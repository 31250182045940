import {
  LookingForSomethingElse,
  publishEvent,
  SectionHeader
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useEffect } from 'react';
import ActiveSessions from 'src/components/ActiveSessions';
import AdvancedSecurityOptions from 'src/components/AdvancedSecurityOptions';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import ContentWrapper from 'src/pages/Security/ContentWrapper';
import { FeatureFlagProps } from 'src/App';
import {
  screenName,
  screenPath,
  SecurityScreenDisplayed
} from 'src/utils/analytics';
import { Content, Header } from './styles';

const Security = (featureFlagProps: FeatureFlagProps) => {
  const { translate } = useDependencyManagerContext();

  const { originalFeedbackExperience: isOriginalFeedbackExperienceEnabled } =
    featureFlagProps;

  useEffect(() => {
    publishEvent(SecurityScreenDisplayed);
  }, []);

  return (
    <>
      <Header data-testid="security-header">
        <SectionHeader
          title={translate('menu.security', 'Security')}
          subtitle={translate(
            'security.description',
            'Manage your login details and what devices are used to log in to your HP account'
          )}
        />
      </Header>
      <Content>
        <ContentWrapper {...featureFlagProps} />
        <AdvancedSecurityOptions />
        <ActiveSessions />
      </Content>
      {isOriginalFeedbackExperienceEnabled && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};

export default Security;
