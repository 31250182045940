import { Loading } from '@hpx-core-experiences/react-my-account-commons/dist';
import React from 'react';
import { FeatureFlagProps } from 'src/App';
import { Center } from 'src/App/styles';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import Security from 'src/pages/Security';
import SecurityDetails from 'src/pages/SecurityDetails';
import { FormTypesEnum } from 'src/utils/enums';
const SUB_ROUTES = Object.values(FormTypesEnum);

const Router = (featureFlagProps: FeatureFlagProps) => {
  const { northboundAPIs } = useDependencyManagerContext();

  const pathName = northboundAPIs.v1.navigation.location.pathname || '';
  const subRouteExists = SUB_ROUTES.includes(
    pathName.split('/').pop() as FormTypesEnum
  );

  // Redirect to security page if the subroute does not exist
  if (pathName.includes('/security/') && !subRouteExists) {
    northboundAPIs.v1.navigation.redirect('/security');
    return (
      <Center>
        <Loading />
      </Center>
    );
  }

  if (subRouteExists) {
    return <SecurityDetails {...featureFlagProps} />;
  }

  return <Security {...featureFlagProps} />;
};

export default Router;
