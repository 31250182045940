import { MainTheme } from '@hpx-core-experiences/react-my-account-commons/dist';
import { Card } from '@veneer/core';
import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Section = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${tokens.layout.size4};
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: ${tokens.layout.size7};
  margin-bottom: ${tokens.layout.size1};
  align-items: center;

  @media (${MainTheme.mediaQueries.small}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.layout.size6};
  &:last-child {
    margin-bottom: ${tokens.layout.size2};
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: ${tokens.layout.size5};

  @media (${MainTheme.mediaQueries.maxWidthMobile}) {
    grid-template-columns: 1fr;
    gap: ${tokens.layout.size2};
  }
`;

export const RowSingleColumn = styled(Row)`
  grid-template-columns: 1fr;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  color: ${tokens.color.gray6};
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;

  && {
    @media screen and (${MainTheme.mediaQueries.maxWidthMobile}) {
      width: 271px;
    }
  }
`;

export const ValueContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Link = styled.div`
  display: flex;
  align-items: center;
  color: ${tokens.color.hpBlue6};
  padding-top: ${tokens.layout.size2};
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
