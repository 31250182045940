import React, { useMemo } from 'react';
import { FeatureFlagProps } from 'src/App';
import LoginDetails from 'src/components/LoginDetails';
import OldLoginDetails from 'src/components/OldLoginDetails';
import Loader from 'src/components/Loader';

const ContentWrapper: React.FC<FeatureFlagProps> = (featureFlagProps) => {
  const { securityListLoginDetails: securityDetailsList, isLoading } =
    featureFlagProps;

  const SecurityDetailsWrapper = useMemo(() => {
    if (isLoading) {
      return <Loader />;
    }

    return securityDetailsList ? (
      <LoginDetails {...featureFlagProps} />
    ) : (
      <OldLoginDetails />
    );
  }, [isLoading, securityDetailsList, featureFlagProps]);

  return <>{SecurityDetailsWrapper}</>;
};

export default ContentWrapper;
