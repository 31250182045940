import { ColumnProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardColumns/index';
import { CardRowProps } from '@hpx-core-experiences/react-my-account-commons/dist/components/Card/CardWrapper/CardRow/index';
import {
  Card,
  formatPhoneNumber,
  ROW_TYPE,
  useReadOnlyDataStreamer
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useState } from 'react';
import { FeatureFlagProps } from 'src/App';
import ErrorBoundary from 'src/App/ErrorBoundary';
import { Value, ValueContent } from 'src/styles/styles';
import {
  ChangePasswordControlButtonClicked,
  ChangePasswordHyperLinkClicked,
  LoginDetailsError,
  ManageEmailControlButtonClicked,
  ManageEmailHyperLinkClicked,
  ManagePhoneControlButtonClicked,
  ManagePhoneHyperLinkClicked
} from 'src/utils/analytics';
import { FormTypesEnum } from 'src/utils/enums';
import getFieldsToFetch from 'src/utils/getFieldsToFetch';
import { useStaticStrings } from 'src/utils/strings';
import { URLS } from 'src/utils/urls';
import Loader from '../Loader';
import Verified from '../Verified';

interface ValueColumnProps {
  value: string;
  verified?: boolean | null;
  dataComponentName?: string;
}

const ValueColumn = ({
  value,
  verified,
  dataComponentName
}: ValueColumnProps) => {
  return (
    <Value>
      <ValueContent
        {...(dataComponentName ? { 'data-component': dataComponentName } : {})}
      >
        {value}
      </ValueContent>
      {verified != null && <Verified verified={verified} />}
    </Value>
  );
};

export interface LoginDetailsType {
  email: string;
  emailVerified: boolean | null;
  phone: string;
  phoneVerified: boolean | null;
}

const LoginDetails = (
  featureFlagProps: FeatureFlagProps
): React.JSX.Element => {
  const [hpIdUser, setHpIdUser] = useState<LoginDetailsType>({
    email: '',
    emailVerified: null,
    phone: '',
    phoneVerified: null
  });

  const { error, loading } = useReadOnlyDataStreamer(
    setHpIdUser,
    getFieldsToFetch
  );

  const {
    fsDataComponentName,
    loginDetailsTitle,
    emailLabel,
    emptyEmail,
    phoneLabel,
    emptyPhone,
    passwordMask,
    passwordLabel
  } = useStaticStrings();

  const {
    securityHPIDEmail: isSecurityHpIdEmailEnabled,
    securityHPIDPassword: isSecurityHpIdPasswordEnabled,
    securityHPIDPhone: isSecurityHpIdPhoneEnabled
  } = featureFlagProps;

  const handleHPIDPhone = (phone: string): string => {
    if (!phone) {
      return null;
    }
    const currentValue = formatPhoneNumber(phone, emptyPhone);
    return currentValue !== emptyPhone ? currentValue : null;
  };

  if (error) {
    return (
      <Card
        title={{
          content: loginDetailsTitle
        }}
        rowsColorChangeEnabled={false}
        childrenStyle={{ align: 'center', marginTop: '16px' }}
        data-testid="error-section"
      >
        <ErrorBoundary
          analyticsEvent={LoginDetailsError('HP ID Widgets error')}
        />
      </Card>
    );
  }

  if (loading) {
    return <Loader />;
  }

  const emailColumns: ColumnProps[] = [];
  const phoneColumns: ColumnProps[] = [];
  const loginDetailsData = {
    email: hpIdUser.email,
    emailVerified: String(hpIdUser.emailVerified).toLowerCase() === 'true',
    phone: handleHPIDPhone(hpIdUser.phone),
    phoneVerified: String(hpIdUser.phoneVerified).toLowerCase() === 'true'
  };

  const getLinkType = (flag: boolean): ROW_TYPE => {
    return flag ? ROW_TYPE.INTERNAL : ROW_TYPE.EXTERNAL;
  };

  emailColumns.push({ value: emailLabel });
  emailColumns.push({
    value: loginDetailsData.email ? (
      <ValueColumn
        value={loginDetailsData.email}
        verified={loginDetailsData.emailVerified}
        dataComponentName={fsDataComponentName}
      />
    ) : (
      emptyEmail
    )
  });

  phoneColumns.push({ value: phoneLabel });
  phoneColumns.push({
    value: loginDetailsData.phone ? (
      <ValueColumn
        value={loginDetailsData.phone}
        verified={loginDetailsData.phoneVerified}
        dataComponentName={fsDataComponentName}
      />
    ) : (
      emptyPhone
    )
  });

  const rows = [
    {
      index: 0,
      content: emailColumns,
      type: getLinkType(isSecurityHpIdEmailEnabled),
      link: {
        url: URLS.HpIdSecurity,
        pathname: `/security/${FormTypesEnum.Email}`,
        dataTestId: 'ManageEmailLink'
      },
      analyticsEvent: isSecurityHpIdEmailEnabled
        ? ManageEmailControlButtonClicked
        : ManageEmailHyperLinkClicked
    },
    {
      index: 1,
      content: phoneColumns,
      type: getLinkType(isSecurityHpIdPhoneEnabled),
      link: {
        url: URLS.HpIdSecurity,
        pathname: `/security/${FormTypesEnum.Phone}`,
        dataTestId: 'ManagePhoneLink'
      },
      analyticsEvent: isSecurityHpIdPhoneEnabled
        ? ManagePhoneControlButtonClicked
        : ManagePhoneHyperLinkClicked
    },
    {
      index: 2,
      content: [{ value: passwordLabel }, { value: passwordMask }],
      type: getLinkType(isSecurityHpIdPasswordEnabled),
      link: {
        url: URLS.HpIdSecurity,
        pathname: `/security/${FormTypesEnum.Password}`,
        dataTestId: 'ChangePasswordLink'
      },
      analyticsEvent: isSecurityHpIdPasswordEnabled
        ? ChangePasswordControlButtonClicked
        : ChangePasswordHyperLinkClicked
    }
  ] as CardRowProps[];

  return (
    <Card
      title={{ content: loginDetailsTitle }}
      rows={rows}
    />
  );
};

export default LoginDetails;
