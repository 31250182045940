import React, { useMemo } from 'react';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import Router from 'src/router';
import * as T from './types';
import '../styles/global.scss';
import {
  ContainerMFE,
  FeatureFlagKeyValues,
  useGetFeatureFlags
} from '@hpx-core-experiences/react-my-account-commons/dist';

export interface FeatureFlagProps {
  isLoading: boolean;
  securityListLoginDetails: boolean;
  securityHPIDEmail: boolean;
  securityHPIDPhone: boolean;
  securityHPIDPassword: boolean;
  originalFeedbackExperience: boolean;
}

const AppComponent: React.FC<T.AppPropsType> = () => {
  const { northboundAPIs } = useDependencyManagerContext();

  const client = useMemo(
    () => northboundAPIs.v1.graphql.getClient(),
    [northboundAPIs.v1.graphql]
  );
  const GraphQLProvider = useMemo(
    () => northboundAPIs.v1.graphql.reactTools.createGraphQLProvider(React),
    [northboundAPIs.v1.graphql.reactTools]
  );

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const featureFlagProps = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [
      FeatureFlagKeyValues.securityHPIDEmail,
      FeatureFlagKeyValues.securityHPIDPhone,
      FeatureFlagKeyValues.securityHPIDPassword,
      FeatureFlagKeyValues.securityListLoginDetails,
      FeatureFlagKeyValues.originalFeedbackExperience
    ]
  });

  return (
    <GraphQLProvider client={client}>
      <ContainerMFE data-testid="security-page">
        <Router {...featureFlagProps} />
      </ContainerMFE>
    </GraphQLProvider>
  );
};

const App: React.FC<T.AppPropsType> = () => {
  return (
    <DependencyManagerProvider>
      <AppComponent />
    </DependencyManagerProvider>
  );
};

export default App;
